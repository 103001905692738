import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import member from '../views/member/index.vue'
import store from '@/store'
import Cookies from 'js-cookie'
import {
  memberInfo
} from '@/api/member'
Vue.use(VueRouter)
const asyncRoutes = [

  {
    path: '/',
    name: 'Home', //框架的layout页面
    component: Home,
    redirect: 'Index',
    meta: {
      title: '首页',
      menu: 'home'
    },
    children: [{
        path: '/index',
        name: 'Index',
        component: () => import('../views/index/dataindex'),
        meta: {
          name: '首页看板',
          menu: 'indexboard'

        }
      },
      {
        path: '/dataindex',
        name: 'DataIndex',
        component: () => import('../views/index/index'),
        meta: {
          name: '首页看板',
          menu: 'indexboard'

        }
      },
      {
        path: '/calendar',
        name: 'Calendar',
        component: () => import('../views/index/calendar'),
        meta: {
          name: '首页日历',
          menu: 'indexcalendar'
        }
      }
    ]
  },
  {
    path: '/block',
    name: 'block', //框架的layout页面
    component: Home,
    redirect: 'blocklocation',
    meta: {
      title: '板块研究',
      menu: 'blockresearch'
    },
    children: [{
        path: '/blocklocation',
        name: 'BlockLocation',
        component: () => import('../views/BlockLocation'),
        meta: {
          name: '板块区位',
          icon: '/pic/bkqw1.png',
          areaMode: 'block', // hide city block
          menu: 'blocklocation'
        }
      },

      {
        path: '/blockintro',
        name: 'BlockIntro',
        component: () => import('../views/BlockIntro.vue'),
        meta: {
          name: '板块简介',
          icon: '/pic/bkji1.png',
          areaMode: 'block', // hide city block
          menu: 'blockintro'
        }
      },
      {
        path: '/blocklayout',
        name: 'BlockLayout',
        component: () => import('../views/BlockLayout.vue'),
        meta: {
          name: '板块区划',
          icon: '/pic/dtms1.png',
          areaMode: 'block', // hide city blocks
          menu: 'blocklayout'
        }
      },
      {
        path: '/blockpopulation',
        name: 'BlockPopulation',
        component: () => import('../views/BlockPopulation.vue'),
        meta: {
          name: '板块人口',
          icon: '/pic/bkrk1.png',
          areaMode: 'block', // hide city block
          menu: 'blockpopulation'
        }
      },
      {
        path: '/blockeconmic',
        name: 'BlockEconmic',
        component: () => import('../views/blockecon/Index.vue'),
        meta: {
          name: '板块经济',
          icon: '/pic/bkjj1.png',
          areaMode: 'block', // hide city block
          menu: 'blockeconmic'
        },
        redirect: '/blockecon/gdp',

        children: [{
            path: '/blockecon/gdp',
            name: 'blockgdp',
            component: () => import('../views/blockecon/Gdp.vue'),
            meta: {
              name: '地区生产总值',
              parentname: '板块经济',
              icon: '/pic/bkjj1.png',
              areaMode: 'block', // hide city block
              menu: 'blockgdp'
            }
          },
          {
            path: '/blockecon/caishou',
            name: 'blockcaizhi',
            component: () => import('../views/blockecon/Caishou.vue'),
            meta: {
              parentname: '板块经济',
              name: '财政收入',
              icon: '/pic/bkjj1.png',
              areaMode: 'block', // hide city block
              menu: 'blockcaishou'
            }
          },
          {
            path: '/blockecon/xue',
            name: 'blockxue',
            component: () => import('../views/blockecon/Xue.vue'),
            meta: {
              parentname: '板块经济',
              name: '学校和幼儿园',
              icon: '/pic/bkjj1.png',
              areaMode: 'block', // hide city block
              menu: 'blockxue'
            }
          },
          {
            path: '/blockecon/yi',
            name: 'blockyi',
            component: () => import('../views/blockecon/Yi.vue'),
            meta: {
              parentname: '板块经济',
              name: '医疗卫生',
              icon: '/pic/bkjj1.png',
              areaMode: 'block', // hide city block
              menu: 'blockyi'
            }
          },

          {
            path: '/blockecon/qi',
            name: 'blockqi',
            component: () => import('../views/blockecon/Qi.vue'),
            meta: {
              parentname: '板块经济',
              name: '上市企业',
              icon: '/pic/bkjj1.png',
              areaMode: 'block', // hide city block
              menu: 'blockqi'
            }
          },
          {
            path: '/blockecon/qiang',
            name: 'blockqiang',
            component: () => import('../views/blockecon/Qiang.vue'),
            meta: {
              parentname: '板块经济',
              name: '500强企业',
              icon: '/pic/bkjj1.png',
              areaMode: 'block', // hide city block
              menu: 'blockqiang'
            }
          }
        ]
      },

      {
        path: '/blockplan',
        name: 'BlockPlan',
        component: () => import('../views/blockplan/Index.vue'),
        meta: {
          name: '板块规划',
          icon: '/pic/bkgh1.png',
          areaMode: 'block', // hide city block
          menu: 'blockplan'
        },
        redirect: '/blockplan/location',
        children: [{
            path: '/blockplan/location',
            name: 'blockplan',
            component: () => import('../views/blockplan/Location.vue'),
            meta: {
              name: '板块定位',
              parentname: '板块规划',
              articleCate: 1,
              icon: '/pic/bkgh1.png',
              areaMode: 'block', // hide city block
              menu: 'blockdingwei'
            }
          },
          {
            path: '/blockplan/card',
            name: 'blockcard',
            component: () => import('../views/blockplan/Card.vue'),
            meta: {
              name: '板块名片',
              parentname: '板块规划',
              articleCate: 2,
              icon: '/pic/bkgh1.png',
              areaMode: 'block', // hide city block
              menu: 'blockcard'
            }
          },
          {
            path: '/blockplan/areamap',
            name: 'blockareamap',
            component: () => import('../views/blockplan/AreaMap.vue'),
            meta: {
              name: '区位图',
              parentname: '板块规划',
              icon: '/pic/bkgh1.png',
              areaMode: 'block', // hide city block
              menu: 'blockareamap'
            }
          },
          // {
          //   path: '/blockplan/traffic',
          //   name: 'blocktraffic',
          //   component: () => import('../views/blockplan/Traffic.vue'),
          //   meta: {
          //     name: '综合交通网络',
          //     parentname: '板块规划',
          //     icon: '/pic/bkgh1.png',
          //     areaMode: 'block', // hide city block,
          //     menu: 'blocktraffic'
          //   }
          // },
          {
            path: '/blockplan/blcokspace',
            name: 'blcokspace',
            component: () => import('../views/blockplan/blcokspace.vue'),
            meta: {
              name: '板块详细规划',
              parentname: '板块规划',
              icon: '/pic/bkgh1.png',
              areaMode: 'block', // hide city block
              menu: 'blcokspace'
            }
          },

          {
            path: '/blockplan/business',
            name: 'blockbusiness',
            component: () => import('../views/blockplan/Business.vue'),
            meta: {
              name: '商业设施',
              parentname: '板块规划',
              icon: '/pic/bkgh1.png',
              areaMode: 'block', // hide city block
              menu: 'blockbusiness'
            }
          },
          {
            path: '/blockplan/industry',
            name: 'blockindustry',
            component: () => import('../views/blockplan/Industry.vue'),
            meta: {
              name: '产业分布',
              parentname: '板块规划',
              icon: '/pic/bkgh1.png',
              areaMode: 'block', // hide city block
              menu: 'blockindustry'

            }
          }
        ]
      },

    ]

  },
  {
    path: '/data',
    name: 'data',
    component: Home,
    redirect: '/data/prepare',
    meta: {
      title: '土地数据',
      menu: 'landdata'
    },
    children: [{
        path: '/data/prepare',
        name: 'Prepare',
        component: () => import('../views/data/Prepare.vue'),
        meta: {
          name: '拟上市',
          areaMode: 'hide', // hide city block
          menu: 'prepare',
          icon: '/pic/nsh1.png',
        }
      },
      {
        path: '/data/notice',
        name: 'Notice',
        component: () => import('../views/data/Notice.vue'),
        meta: {
          name: '正在公告',
          icon: '/pic/zzgg1.png',
          areaMode: 'hide', // hide city block
          menu: 'notice'
        }
      },
      {
        path: '/data/deal',
        name: 'Deal',
        component: () => import('../views/data/Deal.vue'),
        meta: {
          name: '成交数据',
          icon: '/pic/cjsj1.png',
          areaMode: 'hide', // hide city block
          menu: 'deal'
        }
      },
      {
        path: '/data/landanalysis',
        name: 'Landanalysis',
        component: () => import('../views/data/Landanalysis.vue'),
        meta: {
          name: '宗地分析',
          areaMode: 'hide', // hide city block
    
          menu: 'landanalysis',
          icon: '/pic/zdfx1.png',


        }
      },
      {
        path: '/data/landdetail/:id',
        name: 'landdetail',
        component: () => import('../views/data/Index.vue'),
        meta: {
          name: '宗地分析',
          icon: '/pic/zdfx1.png',
          areaMode: 'hide', // hide city block
          menu: 'landdetail'
        },
        redirect: '/data/zhibiao',

        children: [
          // {
          //   path: '/data/landlayout/:id',
          //   name: 'landlayout',
          //   component: () => import('../views/data/landlayout.vue'),
          //   meta: {
          //     name: '地块区位',
          //     parentname: '宗地分析',
          //     icon: '/pic/zdfx1.png',
          //     areaMode: 'hide', // hide city block
          //     menu: 'landdetail'

          //   }
          // },
          {
            path: '/data/zhibiao/:id',
            name: 'zhibiao',
            component: () => import('../views/data/zhibiao.vue'),
            meta: {
              name: '地块规划指标',
              parentname: '宗地分析',
              icon: '/pic/zdfx1.png',
              areaMode: 'hide', // hide city block
              menu: 'landdetail'
            }
          },
          {
            path: '/data/xianzhuang/:id',
            name: 'xianzhuang',
            component: () => import('../views/data/xianzhuang.vue'),
            meta: {
              name: '地块现状',
              parentname: '宗地分析',
              icon: '/pic/zdfx1.png',
              areaMode: 'hide', // hide city block
              menu: 'landdetail'
            }
          },
          {
            path: '/data/720quanjing/:id',
            name: '720quanjing',
            component: () => import('../views/data/720quanjing.vue'),
            meta: {
              name: '720全景看地',
              parentname: '宗地分析',
              icon: '/pic/zdfx1.png',
              areaMode: 'hide', // hide city block
              menu: 'landdetail'
            }
          },

          {
            path: '/data/sizhi/:id',
            name: 'sizhi',
            component: () => import('../views/data/sizhi.vue'),
            meta: {
              name: '宗地四至',
              parentname: '宗地分析',
              icon: '/pic/zdfx1.png',
              areaMode: 'hide', // hide city block
              menu: 'landdetail'
            }
          },
         
          {
            path: '/data/yaodian/:id',
            name: 'yaodian',
            component: () => import('../views/data/yaodian.vue'),
            meta: {
              name: '规划设计要点',
              parentname: '宗地分析',
              icon: '/pic/zdfx1.png',
              areaMode: 'hide', // hide city block
              menu: 'landdetail'
            }
          },
          {
            path: '/data/jiaotong/:id',
            name: 'jiaotong',
            component: () => import('../views/data/jiaotong.vue'),
            meta: {
              name: '交通组织',
              parentname: '宗地分析',
              icon: '/pic/zdfx1.png',
              areaMode: 'hide', // hide city block
              menu: 'landdetail'
            }
          },
          {
            path: '/data/peitao/:id',
            name: 'peitao',
            component: () => import('../views/data/peitao.vue'),
            meta: {
              name: '周边配套',
              parentname: '宗地分析',
              icon: '/pic/zdfx1.png',
              areaMode: 'hide', // hide city block
              menu: 'landdetail'
            }
          },
          {
            path: '/data/jingpin/:id',
            name: 'jingpin',
            component: () => import('../views/data/jingpin.vue'),
            meta: {
              name: '周边竞品',
              parentname: '宗地分析',
              icon: '/pic/zdfx1.png',
              areaMode: 'hide', // hide city block
              menu: 'landdetail'
            }
          },
          {
            path: '/data/fenxian/:id',
            name: 'fenxian',
            component: () => import('../views/data/fenxian.vue'),
            meta: {
              name: '宗地风险点',
              parentname: '宗地分析',
              icon: '/pic/zdfx1.png',
              areaMode: 'hide', // hide city block
              menu: 'landdetail'
            }
          }
        ]
      },

      {
        path: '/data/map',
        name: 'Map',
        component: () => import('../views/data/map.vue'),
        meta: {
          name: '土拍地图',
          icon: '/pic/cjsj1.png',
          areaMode: 'hide', // hide city block
          menu: 'map'
        }
      },


      {
        path: '/data/doclibrarary',
        name: 'Doclibrarary',
        component: () => import('../views/data/Doclibrarary.vue'),
        meta: {
          name: '土拍资料下载',
          areaMode: 'hide', // hide city block
          icon: '/pic/tdtj1.png',
          menu: 'doclibrarary'
        }
      }
    ]
  },
  {
    path: '/',
    name: 'cityresearch', //框架的layout页面
    component: Home,
    meta: {
      title: '城市研究',
      menu: 'cityresearch'
    },
    children: [
      {

        path: '/citylocation',
        name: 'Citylocation',
        component: () => import('../views/CityLocation'),
        meta: {
          name: '城市区位',
          icon: '/pic/csqw1.png',
          areaMode: 'city', // hide city block
          menu: 'citylocation'
        }
      },
      {
        path: '/cityintro',
        name: 'CityIntro',
        component: () => import('../views/CityIntro.vue'),
        meta: {
          name: '城市简介',
          icon: '/pic/csjj1.png',
          areaMode: 'city', // hide city block
          menu: 'cityintro'
        }
      },
      {
        path: '/arealayout',
        name: 'arealayout',
        component: () => import('../views/AreaLayout.vue'),
        meta: {
          name: '行政区划',
          icon: '/pic/dtms1.png',
          areaMode: 'city', // hide city block
          menu: 'cityarealayout'
        }
      },
      {
        path: '/citypopulation',
        name: 'citypopulation',
        component: () => import('../views/CityPopulation.vue'),
        meta: {
          name: '城市人口',
          icon: '/pic/csrk1.png',
          areaMode: 'city', // hide city block
          menu: 'citypopulation'
        }
      },
      {
        path: '/econ',
        name: 'econ',
        component: () => import('../views/econ/Index.vue'),
        meta: {
          name: '城市经济',
          icon: '/pic/csjj1.png',
          areaMode: 'city', // hide city block
          menu: 'cityecon'
        },
        redirect: '/econ/gdp',

        children: [{
            path: '/econ/gdp',
            name: 'gdp',
            component: () => import('../views/econ/Gdp.vue'),
            meta: {
              name: '地区生产总值',
              parentname: '城市经济',
              icon: '/pic/csjj1.png',
              areaMode: 'city', // hide city block
              menu: 'citygdp'
            }
          },
          {
            path: '/econ/gonggdp',
            name: 'gonggdp',
            component: () => import('../views/econ/GongGdp.vue'),
            meta: {
              parentname: '城市经济',
              name: '规模以上工业总产值',
              icon: '/pic/csjj1.png',
              areaMode: 'city', // hide city block
              menu: 'citygonggdp'

            }
          },
          {
            path: '/econ/gutou',
            name: 'gutou',
            component: () => import('../views/econ/Gutou.vue'),
            meta: {
              parentname: '城市经济',
              name: '固定资产投资',
              icon: '/pic/csjj1.png',
              areaMode: 'city', // hide city block
              menu: 'citygutou'

            }
          },
          {
            path: '/econ/shexiao',
            name: 'shexiao',
            component: () => import('../views/econ/Shexiao.vue'),
            meta: {
              parentname: '城市经济',
              name: '社会消费品零售总额',
              icon: '/pic/csjj1.png',
              areaMode: 'city', // hide city block
              menu: 'cityshexiao'
            }
          },
          {
            path: '/econ/jinchukou',
            name: 'jinchukou',
            component: () => import('../views/econ/Jinchukou.vue'),
            meta: {
              parentname: '城市经济',
              name: '城市进出口',
              icon: '/pic/csjj1.png',
              areaMode: 'city', // hide city block
              menu: 'cityjinchukou'
            }
          },
          {
            path: '/econ/waizi',
            name: 'waizi',
            component: () => import('../views/econ/Waizi.vue'),
            meta: {
              parentname: '城市经济',
              name: '利用外资',
              icon: '/pic/csjj1.png',
              areaMode: 'city', // hide city block
              menu: 'citywaizi'
            }
          },
          {
            path: '/econ/dian',
            name: 'dian',
            component: () => import('../views/econ/Dian.vue'),
            meta: {
              parentname: '城市经济',
              name: '用电量',
              icon: '/pic/csjj1.png',
              areaMode: 'city', // hide city block
              menu: 'citydian'
            }
          },
          {
            path: '/econ/caishou',
            name: 'caishou',
            component: () => import('../views/econ/Caishou.vue'),
            meta: {
              parentname: '城市经济',
              name: '财政收入',
              icon: '/pic/csjj1.png',
              areaMode: 'city', // hide city block
              menu: 'citycaishou'
            }
          },
          {
            path: '/econ/caizhi',
            name: 'caizhi',
            component: () => import('../views/econ/Caizhi.vue'),
            meta: {
              parentname: '城市经济',
              name: '财政支出',
              icon: '/pic/csjj1.png',
              areaMode: 'city', // hide city block
              menu: 'citycaizhi'
            }
          },
          {
            path: '/econ/cun',
            name: 'cun',
            component: () => import('../views/econ/Cun.vue'),
            meta: {
              parentname: '城市经济',
              name: '金融机构存款',
              icon: '/pic/csjj1.png',
              areaMode: 'city', // hide city block
              menu: 'citycun'
            }
          },
          {
            path: '/econ/zhuti',
            name: 'zhuti',
            component: () => import('../views/econ/Zhuti.vue'),
            meta: {
              parentname: '城市经济',
              name: '市场主体',
              icon: '/pic/csjj1.png',
              areaMode: 'city', // hide city block
              menu: 'cityzhuti'
            }
          },
          {
            path: '/econ/you',
            name: 'you',
            component: () => import('../views/econ/You.vue'),
            meta: {
              parentname: '城市经济',
              name: '旅游',
              icon: '/pic/csjj1.png',
              areaMode: 'city', // hide city block
              menu: 'cityyou'
            }
          },
          {
            path: '/econ/zhuan',
            name: 'zhuan',
            component: () => import('../views/econ/Zhuan.vue'),
            meta: {
              parentname: '城市经济',
              name: '专利授权量',
              icon: '/pic/csjj1.png',
              areaMode: 'city', // hide city block
              menu: 'cityzhuan'
            }
          },
          {
            path: '/econ/xue',
            name: 'xue',
            component: () => import('../views/econ/Xue.vue'),
            meta: {
              parentname: '城市经济',
              name: '学校和幼儿园',
              icon: '/pic/csjj1.png',
              areaMode: 'city', // hide city block
              menu: 'cityxue'
            }
          },
          {
            path: '/econ/yi',
            name: 'yi',
            component: () => import('../views/econ/Yi.vue'),
            meta: {
              parentname: '城市经济',
              name: '医疗卫生',
              icon: '/pic/csjj1.png',
              areaMode: 'city', // hide city block
              menu: 'cityyi'
            }
          },
          {
            path: '/econ/ren',
            name: 'ren',
            component: () => import('../views/econ/Ren.vue'),
            meta: {
              parentname: '城市经济',
              name: '人才',
              icon: '/pic/csjj1.png',
              areaMode: 'city', // hide city block
              menu: 'cityren'
            }
          },
          {
            path: '/econ/yan',
            name: 'yan',
            component: () => import('../views/econ/Yan.vue'),
            meta: {
              parentname: '城市经济',
              name: '研发机构',
              icon: '/pic/csjj1.png',
              areaMode: 'city', // hide city block
              menu: 'cityyan'
            }
          },
          {
            path: '/econ/zhong',
            name: 'zhong',
            component: () => import('../views/econ/Zhong.vue'),
            meta: {
              parentname: '城市经济',
              name: '众创空间',
              icon: '/pic/csjj1.png',
              areaMode: 'city', // hide city block
              menu: 'cityzhong'
            }
          },
          {
            path: '/econ/qi',
            name: 'qi',
            component: () => import('../views/econ/Qi.vue'),
            meta: {
              parentname: '城市经济',
              name: '上市企业',
              icon: '/pic/csjj1.png',
              areaMode: 'city', // hide city block
              menu: 'cityqi'
            }
          },
          {
            path: '/econ/qiang',
            name: 'qiang',
            component: () => import('../views/econ/Qiang.vue'),
            meta: {
              parentname: '城市经济',
              name: '500强企业',
              icon: '/pic/csjj1.png',
              areaMode: 'city', // hide city block
              menu: 'cityqiang'
            }
          },
          {
            path: '/econ/shouzhi',
            name: 'shouzhi',
            component: () => import('../views/econ/Shouzhi.vue'),
            meta: {
              parentname: '城市经济',
              name: '居民收支',
              icon: '/pic/csjj1.png',
              areaMode: 'city', // hide city block
              menu: 'cityshouzhi'
            }
          }
        ]
      },

      {
        path: '/layout',
        name: 'layout',
        component: () => import('../views/layout/Index.vue'),
        meta: {
          name: '城市规划',
          icon: '/pic/csgh1.png',
          areaMode: 'city', // hide city block
          menu: 'layout'
        },
        redirect: '/layout/location',
        children: [{
            path: '/layout/location',
            name: 'location',
            component: () => import('../views/layout/Location.vue'),
            meta: {
              name: '城市定位',
              parentname: '城市规划',
              articleCate: 1,
              icon: '/pic/csgh1.png',
              areaMode: 'city', // hide city block
              menu: 'citydingwei'
            }
          },
          {
            path: '/layout/card',
            name: 'card',
            component: () => import('../views/layout/Card.vue'),
            meta: {
              name: '城市名片',
              parentname: '城市规划',
              articleCate: 2,
              icon: '/pic/csgh1.png',
              areaMode: 'city', // hide city block
              menu: 'citycard'
            }
          },
          {
            path: '/layout/areamap',
            name: 'areamap',
            component: () => import('../views/layout/AreaMap.vue'),
            meta: {
              name: '区位图',
              parentname: '城市规划',
              icon: '/pic/csgh1.png',
              areaMode: 'city', // hide city block
              menu: 'cityareamap'
            }
          },
          {
            path: '/layout/traffic',
            name: 'traffic',
            component: () => import('../views/layout/Traffic.vue'),
            meta: {
              name: '综合交通网络',
              parentname: '城市规划',
              icon: '/pic/csgh1.png',
              areaMode: 'city', // hide city block
              menu: 'citytraffic'
            }
          },
          {
            path: '/layout/structure',
            name: 'structure',
            component: () => import('../views/layout/Structure.vue'),
            meta: {
              name: '总体空间结构',
              parentname: '城市规划',
              icon: '/pic/csgh1.png',
              areaMode: 'city', // hide city block
              menu: 'citystructure'
            }
          },
          {
            path: '/layout/plan',
            name: 'plan',
            component: () => import('../views/layout/Plan.vue'),
            meta: {
              name: '国土空间规划',
              parentname: '城市规划',
              icon: '/pic/csgh1.png',
              areaMode: 'city', // hide city block
              menu: 'cityplan'
            }
          },
          {
            path: '/layout/business',
            name: 'business',
            component: () => import('../views/layout/Business.vue'),
            meta: {
              name: '商业设施',
              parentname: '城市规划',
              icon: '/pic/csgh1.png',
              areaMode: 'city', // hide city block
              menu: 'citybusiness'
            }
          },
          {
            path: '/layout/industry',
            name: 'industry',
            component: () => import('../views/layout/Industry.vue'),
            meta: {
              name: '产业分布',
              parentname: '城市规划',
              icon: '/pic/csgh1.png',
              areaMode: 'city', // hide city block
              menu: 'cityindustry'
            }
          }
        ]
      },
      {
        path: '/policy',
        name: 'policy',
        component: () => import('../views/policy/Index.vue'),
        meta: {
          name: '城市政策',
          icon: '/pic/cszc1.png',
          areaMode: 'city', // hide city block
          menu: 'policy'

        },
        redirect: '/policy/land',
        children: [{
            path: '/policy/land',
            name: 'land',
            component: () => import('../views/policy/Land.vue'),
            meta: {
              name: '土地政策',
              parentname: '城市政策',
              articleCate: 3,
              icon: '/pic/cszc1.png',
              areaMode: 'city', // hide city block
              menu: 'citypolicyland'
            }
          },
          {
            path: '/policy/fang',
            name: 'fang',
            component: () => import('../views/policy/Fang.vue'),
            meta: {
              name: '房产政策',
              parentname: '城市政策',
              articleCate: 4,
              icon: '/pic/cszc1.png',
              areaMode: 'city', // hide city block
              menu: 'citypolicyfang'
            }
          },
          {
            path: '/policy/hu',
            name: 'hu',
            component: () => import('../views/policy/Hu.vue'),
            meta: {
              name: '落户政策',
              parentname: '城市政策',
              articleCate: 5,
              icon: '/pic/cszc1.png',
              areaMode: 'city', // hide city block
              menu: 'citypolicyhu'
            }
          },
          {
            path: '/policy/cai',
            name: 'cai',
            component: () => import('../views/policy/Cai.vue'),
            meta: {
              name: '人才政策',
              parentname: '城市政策',
              articleCate: 6,
              icon: '/pic/cszc1.png',
              areaMode: 'city', // hide city block
              menu: 'citypolicycai'
            }
          }

        ]
      },

    ],
  },
]


const constantRoutes = [{
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/login.vue'),
    meta: {
      name: '登录'
    }
  },
  {
    path: '/bindtelphone',
    name: 'Bindtelphone',
    component: () => import('../views/login/bindtelphone.vue'),
    meta: {
      name: '绑定手机号'
    }
  },
  {
    path: '/scanloading',
    name: 'scanloading',
    component: () => import('../views/login/scanloading.vue'),
    meta: {
      name: '扫码登录'
    }
  },
  {
    path: '/bindmemberinfo',
    name: 'bindmemberinfo',
    component: () => import('../views/login/bindmemberinfo.vue'),
    meta: {
      name: '注册信息'
    }
  },





  // {
  //   path: '/member',
  //   name: 'member',
  //   component:member ,
  //   redirect: '/member/byvip',
  // children: [{
  //   path: '/member/buyvip',
  //   name: 'Buyvip',
  //   component: () => import('../views/member/buyvip.vue'),
  //   meta: {
  //     name: '土拍会员',
  //     areaMode: 'hide', // hide city block
  //   }
  // },{
  //   path: '/member/personal',
  //   name: 'personal',
  //   component: () => import('../views/member/personal.vue'),
  //   meta: {
  //     name: '个人中心',
  //     areaMode: 'hide', // hide city block
  //   }
  // },{
  //   path: '/member/repassword',
  //   name: 'repassword',
  //   component: () => import('../views/member/repassword.vue'),
  //   meta: {
  //     name: '修改密码',
  //     areaMode: 'hide', // hide city block
  //   }
  // },{
  //   path: '/member/changephone',
  //   name: 'changephone',
  //   component: () => import('../views/member/changephone.vue'),
  //   meta: {
  //     name: '换绑手机',
  //     areaMode: 'hide', // hide city block
  //   }
  // },
  // {
  //   path: '/member/buyrecord',
  //   name: 'buyrecord',
  //   component: () => import('../views/member/buyrecord.vue'),
  //   meta: {
  //     name: '购买记录',
  //     areaMode: 'hide', // hide city block
  //   }
  // }
  // ]

  // }
]

const routes = constantRoutes

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

//路由前置的钩子   存储store当前路由 当request请求时 可获取当前路由进行登录判断
// router.beforeEach((to, from, next) => {
//   store.commit('setRoute',to)
//   const token = Cookies.get('token')
//   if(token){
//     //登录存储token
//     store.commit('setToken',token)
//     //获取用户信息
//     memberInfo().then(res=>{
//       const info = res.data
//       console.log('info',info)
//     })
//   }
//   next()
// })


export default router

//到处econ的经济路由
//  const econ = routes[0].children
export {
  routes,
  constantRoutes,
  asyncRoutes
}